<template>
  <TableViewWithTitle title="담당자 정보">
    <template v-slot:header>
      <tr>
        <th colspan="2">구매 담당</th>
        <th colspan="2">현업 담당</th>
      </tr>
    </template>
    <template v-slot:body>
      <tr>
        <th>구매 담당자</th>
        <td>{{ purchaseManager | idWithNameByObj }}</td>
        <th>현업 담당자<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="onClickPopLdapManager('contractRequester')"
            >
              조직도 검색
            </button>
            <!--<Input
              placeholder="현업 담당자명을 입력하세요."
              :value.sync="realManager.name" />-->
            <Input
              style="width: 272px"
              placeholder="조직도 아이디를 입력하세요"
              :isDisabled="true"
              :value.sync="realManagerIdWithName"
            />
          </div>
        </td>
      </tr>
      <tr>
        <th>전화번호 (내선)</th>
        <td>{{ purchaseManager.tel }}</td>
        <th>전화번호 (내선)</th>
        <td>
          <InputPhone
            :useMobile="true"
            :useArea="true"
            :phone0.sync="realManager.tel.phone0"
            :phone1.sync="realManager.tel.phone1"
            :phone2.sync="realManager.tel.phone2"
          />
        </td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>{{ purchaseManager.email }}</td>
        <th>이메일</th>
        <td>
          <InputEmail
            :emailName.sync="realManager.email.name"
            :emailDomain.sync="realManager.email.domain"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import SelectBox from "@/components/common/selectBox/SelectBox";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
    Input,
    SelectBox,
    InputEmail,
    InputPhone,
  },
  props: {
    realManager: Object,
    purchaseManager: Object,
    realManagerIdWithName: String,
  },
  methods: {
    onClickPopLdapManager(target) {
      //this.isPopLdapManager = true;
      this.$emit("onClickPopLdap", target);
    },
  },
};
</script>
