<template>
  <TableViewWithTitle title="입찰상품 정보">
    <template v-slot:body>
      <tr>
        <th>구매품목<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input placeholder="상품명을 입력하세요." :value.sync="dataSync.itemName" />
        </td>
        <th>입찰수량<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <SelectBoxQuantityIncludeData
            :count.sync="dataSync.quantity"
            :unitValue.sync="dataSync.unitValue"
            :unitType.sync="dataSync.unitType"
          />
        </td>
      </tr>
      <tr>
        <th>예산</th>
        <td>
          <InputAutoMoney
            :inputClass="'align_right'"
            :maxLength="14"
            :value.sync="dataSync.budget"
          />
          <!-- <SelectBoxCurrencyIncludeData
            :isAutoMoneyType="true"
            placeholderCount="예산을 입력하세요."
            :count.sync="dataSync.budget"
            :value.sync="dataSync.currency"
          /> -->
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th>RFP</th>
        <td colspan="3">
          <FileWrite :dataList="fileList" @fileUploaded="fileUploaded" @fileRemoved="fileRemoved" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import SelectBoxQuantityIncludeData from "@/components/common/selectBox/SelectBoxQuantityIncludeData";
import SelectBoxCurrencyIncludeData from "@/components/common/selectBox/SelectBoxCurrencyIncludeData";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import FileWrite from "@/components/common/file/FileWrite";

export default {
  name: "ProductInfo",
  components: {
    TableViewWithTitle,
    Input,
    SelectBoxQuantityIncludeData,
    SelectBoxCurrencyIncludeData,
    InputAutoMoney,
    FileWrite,
  },
  props: {
    dataSync: Object,
    fileList: Array,
  },
  methods: {
    fileUploaded(files) {
      this.$emit("update:fileList", files);
    },
    fileRemoved(itemToRemove) {
      this.$emit("onRemovedFile", itemToRemove);
    },
  },
};
</script>
