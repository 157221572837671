<template>
  <TableViewWithTitle title="평가방법 설정">
    <template v-slot:body>
      <tr>
        <th class="th_mid" rowspan="2">업체 선정 방식</th>
        <td>
          <div class="group_form">
            <RadioItem
              id="P"
              class="w105"
              text="가격 (100%)"
              name="choice"
              :selectedId.sync="dataSync.choiceType"
            />
          </div>
        </td>
        <th rowspan="2">가격 공개 방식</th>
        <td>
          <RadioItem
            id="C"
            text="입찰마감후 공개"
            name="price"
            :selectedId.sync="dataSync.priceType"
          />
        </td>
      </tr>
      <tr>
        <td>
          <div class="group_form">
            <RadioItem
              id="C"
              class="w105"
              text="가격+기술"
              name="choice"
              :selectedId.sync="dataSync.choiceType"
            />
            <Input
              class="w96"
              placeholder="가격"
              :value.sync="dataSync.pricePercent"
              :isDisabled="dataSync.choiceType === 'P'"
            />
            <span class="txt_view_r">%</span>
            <span class="txt_view_c w40">+</span>
            <Input
              class="w100"
              placeholder="기술"
              :value.sync="dataSync.skillPercent"
              :isDisabled="dataSync.choiceType === 'P'"
            />
            <span class="txt_view_r">%</span>
          </div>
        </td>
        <td>
          <RadioItem id="O" text="공개" name="price" :selectedId.sync="dataSync.priceType" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import RadioItem from "@/components/common/radio/RadioItem";

export default {
  name: "EvaluationSetting",
  components: {
    TableViewWithTitle,
    Input,
    RadioItem,
  },
  props: {
    dataSync: {
      priceType: String,
      choiceType: String,
      pricePercent: String,
      skillPercent: String,
    },
  },
};
</script>
