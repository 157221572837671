<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">공고 등록</h2>
      </div>
      <div class="body_section">
        <AnnounceInfo
          ref="announceInfo"
          :newNumber="newNumber"
          :dataSync="announceInfoData"
          :purchaseWfDataList="purchaseWfPostList"
          :requestPostList="requestPostList"
          :itemSelected="purchaseWfPostList"
          @onClickPurchaseWfPostSearch="onClickPurchaseWfPostSearch"
          @onClickConnectPurchaseWfPost="onClickConnectPurchaseWfPost"
          @onClickRemovePurchaseWfPost="onClickRemovePurchaseWfPost"
        />
        <!-- @onClickConnectRequestPost="onClickConnectRequestPost" -->
        <!-- <PurchaseJiraConnectWrite
          v-if="isShowPurchaseJira && requestPostList.length > 0"
          :connectDataList="requestPostList" /> -->
        <!-- <PurchaseRequestConnectWrite
          v-if="isShowPurchaseRequest && requestPostList.length > 0"
          :connectDataList="requestPostList" /> -->
        <ManagerInfo
          :realManager.sync="realManager"
          :realManagerIdWithName="realManager | idWithNameByObj"
          :purchaseManager="purchaseManager"
          @onClickPopLdap="onClickPopLdap"
        />
        <ProductInfo
          :dataSync.sync="productInfo"
          :fileList="fileList"
          @update:fileList="onUploadCompleteFiles"
          @onRemovedFile="onRemovedFile"
        />
        <div class="area_view">
          <TableHead title="입찰상품 추가 정보" />
          <div class="editor_comm">
            <!-- method=""-->
            <form>
              <textarea id="summernote" name="editordata" />
            </form>
          </div>
        </div>
        <EvaluationSetting :dataSync="postEvaluate" />
        <RequestPartnersInfo
          :title="'입찰 요청업체 정보'"
          :postPersonList="postPersonList"
          :isEssential="isEssential"
          @onClickPopPartner="onClickPopPartner"
          @onClickRemoveItem="onClickRemoveItem"
        />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickCancel">취소</button>
        <template v-if="status === 'T'">
          <button class="btn_fifthly btn_large" @click="onClickAnnounceRemove">삭제</button>
        </template>
      </div>
      <div class="area_right">
        <button type="button" class="btn_secondary btn_large" @click="onClickSaveTemp">
          임시저장
        </button>
        <button type="submit" class="btn_primary btn_large" @click="onClickAlertComfirm">
          공고 게시
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isAlertComfirm"
        alertText="공고를 게시 하시겠습니까?<br>*설정된 요청 업체 정보에 따라 메일 알림이 발송되며<br>더 이상 수정할 수 없습니다.<br>게시하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="onClickConfirm"
      />
      <PurchaseRequestsPopup
        v-if="isPopPurchaseWfPost"
        :purchaseWfDataList.sync="purchaseWfPostList"
        :isMultiMode="true"
        :type="'P'"
        @onClickClose="onClickClosePopPurchaseWfPost"
        @setPurchaseContract="setPurchaseContract"
      />
      <PurchaseRequestDetailPopup
        v-if="isPopRequestPost"
        :requestCid="requestCidSelected"
        @onClickClose="onClickCloseRequestPost"
        @alert="onAlert"
      />
      <AnnouncePartnerPopup
        v-if="isPopPartners"
        @onClickClose="onClickClosePopPartner"
        @onClickAdd="onClickAddPopPartner"
      />
      <AlertPopup
        v-if="isAlertCancel"
        alertText="공고 등록을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickAlertCancel"
        @onClickPrimary="onClickAlertOk"
      />
      <DirectorLdapPopup
        v-if="isPopLdap"
        @onClickSelect="onClickSelectLdap"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import AnnounceInfo from "@/components/admin/announce/write/AnnounceInfo";
// import PurchaseJiraConnectWrite from '@/components/shared/purchaseJira/PurchaseJiraConnectWrite'
// import PurchaseRequestConnectWrite from '@/components/shared/purchaseRequest/PurchaseRequestConnectWrite'
import ManagerInfo from "@/components/admin/announce/write/ManagerInfo";
import ProductInfo from "@/components/admin/announce/write/ProductInfo";
import TableHead from "@/components/shared/TableHead";
import EvaluationSetting from "@/components/admin/announce/write/EvaluationSetting";
import RequestPartnersInfo from "@/components/admin/announce/write/RequestPartnersInfo";

import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import AnnouncePartnerPopup from "@/components/admin/announce/popup/AnnouncePartnerPopup";
import DirectorLdapPopup from "@/components/admin/system/director/popup/DirectorLdapPopup";
import PurchaseRequestsPopup from "@/components/shared/purchaseRequestPopup/PurchaseRequestsPopup";
import PurchaseRequestDetailPopup from "@/components/admin/purchaseRequest/popup/PurchaseRequestDetailPopup";

import PageMixin from "@/mixins/PageMixin";

import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";

import { dateToStringWithTime, stringToDate } from "@/utils/dateUtils";
import { decodeHTMLEntities } from "@/utils/stringUtils";
import { getIsValidPhone, getPhoneTextFull, setPhone } from "@/utils/phoneUtils";
import { getIsValidEmail, getEmailTextFull, setEmail } from "@/utils/emailUtils";
import { assign } from "@/utils/arrayUtils";
import { getCanConvertNumber } from "@/utils/numberUtils";
import { getPurchaseDraftDetailPath } from "@/utils/DetailPathUtils";

import { ANNOUNCE_WRITE_ACTION } from "@/store/modules/announce/action";
import ReferenceMixin from "@/mixins/ReferenceMixin";

export default {
  name: "AnnounceWrite",
  components: {
    PageWithLayout,
    AnnounceInfo,
    // PurchaseJiraConnectWrite,
    // PurchaseRequestConnectWrite,
    ManagerInfo,
    ProductInfo,
    TableHead,
    EvaluationSetting,
    RequestPartnersInfo,
    Sticky,
    AlertPopup,
    AnnouncePartnerPopup,
    DirectorLdapPopup,
    PurchaseRequestsPopup,
    PurchaseRequestDetailPopup,
  },
  mixins: [PageMixin, ReferenceMixin],
  data() {
    return {
      isModifyMode: false,
      status: "",
      newNumber: "", // 입찰 공고 번호
      requestCid: null, // 구매요청서 번호.

      purchaseWfPostList: [],
      isPopPurchaseWfPost: false,

      requestCidSelected: null,
      isPopRequestPost: false,

      purchaseManager: {
        code: "P",
        email: "",
        loginId: "",
        name: "",
        tel: "",
        // personNum: '',
      },
      realManager: {
        loginId: "",
        name: "",
        tel: {
          phone0: "02",
          phone1: "",
          phone2: "",
        },
        email: {
          name: "",
          domain: "",
        },
        personNum: "",
      },

      announceInfoData: {
        title: "", // 입찰 공고명,
        startDate: null,
        endDate: null,

        category0: null,
        category1: null,
        category2: null,
      },

      // callPostList: [
      //   {
      //     category:"SW,개발,Adobe",
      //     goods:"시오 테스트 - 자산관리시스템 연동",
      //     requestCid:"DKYHNW6GURFR",
      //     requestDate:"20191210124258",
      //     requester:"sio.oh",
      //     signDate:"20191210124333",
      //     signer:"jenny.park",
      //     title:"시오 테스트 - 자산관리시스템 연동",
      //   }
      // ],

      productInfo: {
        itemName: "",
        quantity: "",
        currency: "KRW",
        unitValue: "EA",
        unitType: "E",
        budget: "",
      },
      fileList: [],

      postEvaluate: {
        choiceType: "P", // 가격 공개 타입: C: 가격+기술, P: 가격
        priceType: "C", // O: 공개, C: 입찰마감후 공개
        pricePercent: "",
        skillPercent: "",
      },

      postPersonList: [],
      isEssential: true,

      isPopPartners: false,
      isAlertComfirm: false,
      isAlertCancel: false,

      requestPostList: [],
      isPopLdap: false,
      //realLadapManager:''
      id: "",

      beforeWritingPage: "",
    };
  },
  computed: {
    isShowPurchaseRequest() {
      return LocalStorageManager.shared.getIsShowPurchaseRequest();
    },
  },
  async beforeMount() {
    this.newNumber = this.$route.params.id;
    const requestCid = this.$route.query.requestCid || this.$route.params.requestCid;
    if (requestCid) {
      this.requestCid = requestCid;
      await this.fetchDraftRequestData(requestCid);
      this.setPurchaseInfo(this.$route.params.type, requestCid);
    }
    const { id } = this.$route.params;
    this.id = id;

    this.isModifyMode = this.$route.meta.isModifyMode;
    const { beforeWritingPage } = this.$route.params;
    if (beforeWritingPage) this.beforeWritingPage = beforeWritingPage;
  },
  mounted() {
    this.createSummernote();

    const { prevData } = this.$route.params;

    this.$store.dispatch(ANNOUNCE_WRITE_ACTION).then(() => {
      if (prevData) {
        this.setData(prevData);
        this.getPurchaseRequest();
      } else if (this.isModifyMode) {
        this.getItem(this.newNumber);
      }
      // else {
      //   this.getPurchaseRequest();
      // }
    });

    this.setPManagerData();

    // 임시 test용.
    // this.getPurchaseRegisterList( [ 're01_20191201_0005' ] );

    if (this.$route.params.purchaseRequestIdList) {
      // this.getPurchaseRegisterList( this.$route.params.purchaseRequestIdList );

      this.requestPostList = this.$route.params.purchaseRequestIdList;
    }
  },
  beforeDestroy() {
    const summernote = $("#summernote");
    if (summernote) summernote.summernote("destroy");
  },
  methods: {
    async getPurchaseRequest() {
      // !this.isShowPurchaseJira ||
      if (!this.requestCid) return;

      const result = await ApiService.shared.getData(
        `${this.$apiPath.PURCHASE_JIRA_REGIST}/${this.requestCid}`,
      );
      if (!result.data) {
        this.alert(result.text);
      }

      this.requestPostList = [result.data];

      this.checkRequesterLdap();
    },
    checkRequesterLdap() {
      if (!this.requestPostList) return;

      const { requester } = this.requestPostList[0];
      if (!requester) return;

      const arr = requester.split("(");
      if (arr.length !== 2) return;

      const requesterId = arr[0];
      if (!requesterId) return;

      const arrRest = arr[1].split(")");
      if (arrRest.length < 1) return;

      const requesterName = arrRest[0];

      this.getLdapData(requesterId, requesterName);
    },
    async getLdapData(requesterId, requesterName) {
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${requesterId}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        // this.alert( result.text );
        return;
      }

      const requester = result.data.find((item) => item.personName === requesterName);
      if (!requester) return;
      const arrEmail = requester.emailId.split("@");

      // 계약 요청자
      this.realManager = {
        loginId: requester.accountId,
        name: requester.personName,
        tel: {
          phone0: "02",
          phone1: "",
          phone2: "",
        },
        email: {
          name: arrEmail[0],
          domain: arrEmail[1],
        },
      };
    },
    // async getPurchaseRegisterList( arr ) {
    //   const result = await ApiService.shared.postData( this.$apiPath.PURCHASE_REGISTER, arr );

    //   this.requestPostList = result.data || [];
    // },
    async getItem(id) {
      const result = await ApiService.shared.getData(`${this.$apiPath.ANNOUNCE}/${id}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.setData(result.data);
    },
    setData(dataFromServer) {
      const {
        status,
        title,
        startDate,
        endDate,
        purchaseWfPostList,
        itemName,
        budget,
        attachFileList,
        note,
        purchasePersonList,
        postEvaluate,
        postPersonList,
        cate1,
        cate2,
        cate3,
        currency,
        quantity,
        requestPostList,
        unitType,
        unitValue,
      } = dataFromServer;
      this.status = status;

      this.announceInfoData.title = title;

      this.announceInfoData.startDate = startDate ? stringToDate(startDate) : null;
      this.announceInfoData.endDate = endDate ? stringToDate(endDate) : null;

      this.setCategoryForce(cate1, cate2, cate3);

      this.purchaseWfPostList = purchaseWfPostList || [];

      this.productInfo.itemName = itemName;
      this.productInfo.quantity = quantity;
      this.productInfo.budget = budget;

      this.productInfo.currency = currency;
      this.productInfo.unitType = unitType;
      this.productInfo.unitValue = unitValue;

      if (attachFileList) {
        this.fileList = attachFileList;
      }

      const realManager = purchasePersonList.find((item) => item.code === "B");
      this.realManager.loginId = realManager.loginId;
      this.realManager.name = realManager.name;
      if (realManager.email) {
        setEmail(realManager.email, this.realManager.email);
      }
      if (realManager.tel) {
        setPhone(realManager.tel, this.realManager.tel);
      }
      this.realManager.personNum = realManager.personNum;

      // const purchaseManager = purchasePersonList.find( item => item.code === 'P' );
      // this.purchaseManager.loginId = purchaseManager.loginId;
      // this.purchaseManager.name = purchaseManager.name;
      // this.purchaseManager.email = purchaseManager.email;
      // this.purchaseManager.tel = purchaseManager.tel;
      // this.purchaseManager.personNum = purchaseManager.personNum;

      this.postEvaluate = postEvaluate;

      if (postPersonList) {
        this.postPersonList = postPersonList;
      }

      const noteDecoded = decodeHTMLEntities(document, note);

      $("#summernote").summernote("code", noteDecoded);

      this.requestPostList = requestPostList || [];
    },
    setPManagerData() {
      const userData = LocalStorageManager.shared.getUserData();
      this.purchaseManager = {
        code: "P",
        email: userData.email || "",
        loginId: userData.username || "",
        name: userData.name || "",
        tel: userData.tel || "",
      };
    },
    setCategoryForce(cate1, cate2, cate3) {
      this.announceInfoData.category0 = cate1 || null;
      this.announceInfoData.category1 = cate2 || null;
      this.announceInfoData.category2 = cate3 || null;

      this.$refs.announceInfo.setCategoryForce(cate1, cate2, cate3);
    },
    // 구매요청서 선택 팝업 열기
    onClickPurchaseWfPostSearch() {
      this.isPopPurchaseWfPost = true;
    },
    // 구매요청서 선택 팝업 닫기
    onClickClosePopPurchaseWfPost() {
      this.isPopPurchaseWfPost = false;
    },
    // 구매요청서 선택 팝업 선택시
    // onClickSelectPurchaseWfPost( selectedItem ){
    //   this.purchaseWfPostList.push(selectedItem);
    //   this.isPopPurchaseWfPost = false;
    // },
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    // 구매요청서 제거시
    onClickRemovePurchaseWfPost(deletedDataList) {
      this.purchaseWfPostList = this.purchaseWfPostList.filter(
        (item) => item.requestCid != deletedDataList.requestCid,
      );
    },

    onAlert(text) {
      this.alert(text);
    },
    onClickSaveTemp() {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      const obj = this.getObjectForSave();

      this.add(obj, true);
    },
    onClickConfirm() {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      const obj = this.getObjectForSave();

      this.add(obj);
    },
    async add(obj, isTemp = false) {
      this.isAlertComfirm = false;

      let result;

      const path = `${this.$apiPath.ANNOUNCE}/${this.newNumber}?temp=${isTemp ? "Y" : "N"}`;

      if (this.isModifyMode) {
        result = await ApiService.shared.putData(path, obj);
      } else {
        result = await ApiService.shared.postData(path, obj);
      }

      if (result.code !== "200") {
        // this.alert( result.response.data.text );
        this.alert(result.text);
        return;
      }

      // const refreshPath = isTemp ? `${ this.$routerPath.ANNOUNCE_MODIFY }/${ this.id }` : `${ this.$routerPath.ANNOUNCE_VIEW }/${ this.id }`
      // 저장 성공 시 해당 화면 refresh
      this.$router.push({
        // path: this.$routerPath.ANNOUNCE_LIST
        // path: refreshPath
        path: `${this.$routerPath.ANNOUNCE_VIEW}/${this.id}`,
      });
    },
    getAlertMessage() {
      if (!this.announceInfoData.startDate) return "입찰 시작일을 선택해주세요.";
      if (!this.announceInfoData.endDate) return "입찰 종료일을 선택해주세요.";
      if (!this.announceInfoData.title.trim()) return "입찰공고명을 입력해주세요.";
      if (!this.announceInfoData.category0) return "분류를 선택해주세요."; // 필수값 대분류로 변경.

      if (!this.realManager.name.trim()) return "현업 담당자명을 입력해주세요.";
      // if( !getIsValidPhone( this.realManager.tel ) ) return '현업 담당자 전화번호를 입력해주세요.'
      // if( !getIsValidEmail( this.realManager.email ) ) return '현업 담당자 이메일을 입력해주세요.'

      if (!this.productInfo.itemName.trim()) return "구매 품목을 입력해주세요.";
      if (!this.productInfo.quantity.trim()) return "입찰 수량을 입력해주세요.";

      if (this.postPersonList.length < 1) return "입찰 요청 업체를 선택해주세요.";

      if (this.postEvaluate.choiceType === "C") {
        const alertChoice = "가격+기술 합계는 100%로 입력 가능 합니다.";

        if (!getCanConvertNumber(this.postEvaluate.pricePercent)) return alertChoice;
        if (!getCanConvertNumber(this.postEvaluate.skillPercent)) return alertChoice;

        const nPrice = Number.parseInt(this.postEvaluate.pricePercent, 10);
        const nSkill = Number.parseInt(this.postEvaluate.skillPercent, 10);

        if (nPrice + nSkill !== 100) return alertChoice;
      }

      return null;
    },
    onUploadCompleteFiles(files) {
      if (!files) {
        this.alert("업로드에 실패하였습니다.");
        return;
      }
      this.fileList = this.fileList.concat(files);
    },
    onRemovedFile(file) {
      this.fileList = this.fileList.filter((item) => item !== file);
    },
    onClickCancel() {
      this.isAlertCancel = true;
    },
    onClickAlertOk() {
      if (this.beforeWritingPage) {
        this.$router.push({
          path: this.beforeWritingPage,
        });
      } else {
        this.$router.push({
          path: this.$routerPath.ANNOUNCE_LIST,
        });
      }
      // this.$router.push({
      //   path: this.$routerPath.ANNOUNCE_LIST
      // });
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickAlertComfirm() {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      this.isAlertComfirm = true;
    },
    onClickCloseAlertComfirm() {
      this.isAlertComfirm = false;
    },
    onClickPopPartner() {
      this.isPopPartners = true;
    },
    onClickRemoveItem(itemToRemove) {
      this.postPersonList = this.postPersonList.filter((item) => item !== itemToRemove);
    },
    onClickClosePopPartner() {
      this.isPopPartners = false;
    },
    onClickAddPopPartner(checkedNames) {
      this.postPersonList = assign(this.postPersonList, checkedNames, "personNum");

      this.isPopPartners = false;
    },
    getObjectForSave() {
      var markupStr = $("#summernote").summernote("code");

      var obj = {
        orderYn: false, // 이게 기본 값으로 되어 있음..

        startDate: dateToStringWithTime(this.announceInfoData.startDate),
        endDate: dateToStringWithTime(this.announceInfoData.endDate),
        title: this.announceInfoData.title,
        cateNum:
          this.announceInfoData.category2 ||
          this.announceInfoData.category1 ||
          this.announceInfoData.category0, // 카테고리 필수값 대분류까지로.

        itemName: this.productInfo.itemName,
        quantity: this.productInfo.quantity,
        // 예산 콤마 빼고 저장.
        budget: this.productInfo.budget.split(",").join(""),

        currency: this.productInfo.currency,
        unitType: this.productInfo.unitType,
        unitValue: this.productInfo.unitValue,

        note: markupStr,
      };

      obj.purchaseWfPostList = this.purchaseWfPostList.map((item) => {
        return { requestCid: item.requestCid };
      });

      obj.purchasePersonList = [
        this.purchaseManager,
        {
          code: "B",
          email: getIsValidEmail(this.realManager.email)
            ? getEmailTextFull(this.realManager.email)
            : "",
          loginId: this.realManager.loginId,
          name: this.realManager.name,
          tel: getIsValidPhone(this.realManager.tel) ? getPhoneTextFull(this.realManager.tel) : "",
          personNum: this.realManager.personNum,
        },
      ];

      const isPriceOnly = this.postEvaluate.choiceType === "P";

      obj.postEvaluate = {
        choiceType: this.postEvaluate.choiceType,
        priceType: this.postEvaluate.priceType,
        pricePercent: isPriceOnly ? "100" : this.postEvaluate.pricePercent,
        skillPercent: isPriceOnly ? "0" : this.postEvaluate.skillPercent,
      };

      if (this.fileList.length > 0) {
        obj.attachFileList = this.fileList;
      }

      obj.postPersonList = this.postPersonList;

      // 구매 신청 목록
      if (this.requestPostList) {
        obj.requestPostList = this.requestPostList;
      }

      return obj;
    },
    onClickRemove(itemToRemove) {
      this.requestPostList = this.requestPostList.filter((item) => item !== itemToRemove);
    },
    onClickPopLdap(target) {
      this.isPopLdap = true;
      //this.popLdapTarget = target;
    },
    onClickClosePopLdap() {
      this.isPopLdap = false;
    },
    onClickSelectLdap(rowData) {
      this.isPopLdap = false;
      this.realManager.code = "B";
      this.realManager.loginId = rowData.accountId;
      this.realManager.name = rowData.personName;

      if (rowData.emailId) {
        setEmail(rowData.emailId, this.realManager.email);
      }
      // 구) 시스템
      // if (rowData.officePhone) {
      //   setPhone(rowData.officePhone, this.realManager.tel);
      //}
      if (rowData.tel) {
        setPhone(rowData.tel, this.realManager.tel);
      }
    },
    async fetchDraftRequestData(requestCid) {
      const res = await ApiService.shared.getData(
        `${this.$apiPath.DRAFT_LIST}?requestCid=${requestCid}`,
      );
      if (parseInt(res.code) === 200 && res.data.length > 0) {
        const { title, requester } = res.data[0];
        this.purchaseWfPostList = [{ requestCid, title, requester }];
      }
    },
    setPurchaseContract(item) {
      let tmp = "";
      if (item.length === 1) {
        tmp = item[0].title;
      } else if (item.length > 1) {
        tmp = `${item[0].title} 외 ${item.length - 1}`;
      }
      this.announceInfoData.title = tmp;
      if (item[0]) this.setBManager(item[0].accountId);
    },
    // 계약서 자동입력
    async setPurchaseInfo(type, requestCid) {
      const obj = await this.getPurchaseContract(requestCid);
      this.purchaseWfPostList.push(obj);
      this.setPurchaseContract(this.purchaseWfPostList);
    },
    // 현업담당자 자동입력
    async setBManager(accountId) {
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${accountId}`;
      const result = await ApiService.shared.getData(path);
      if (result.data && result.data.length > 0) {
        this.onClickSelectLdap(result.data[0]);
      }
    },
    onClickAnnounceRemove() {
      const funcRemove = async () => {
        const path = `${this.$apiPath.ANNOUNCE}/${this.id}`;
        const result = await ApiService.shared.deleteData(path);
        const { code, message } = result;
        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

          return;
        }
        this.$router.push({
          path: this.$routerPath.ANNOUNCE_LIST,
        });
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: "현재 공고를 삭제하시겠습니까?",
        onClickY: funcRemove,
      });
    },
  },
};
</script>
