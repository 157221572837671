<template>
  <TableViewWithTitle title="입찰공고 정보">
    <template v-slot:body>
      <tr>
        <th>입찰 공고번호</th>
        <td>{{ newNumber }}</td>
        <th>입찰 시작일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DateTimePicker :date.sync="dataSync.startDate" />
        </td>
      </tr>
      <tr>
        <th>입찰공고명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input
            :maxLength="30"
            placeholder="입찰공고명을 입력하세요."
            :value.sync="dataSync.title"
          />
        </td>
        <th>입찰 마감일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DateTimePicker :date.sync="dataSync.endDate" />
        </td>
      </tr>
      <tr>
        <th>분류<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <CategoryGroupIncludeData
            ref="category"
            :category0.sync="dataSync.category0"
            :category1.sync="dataSync.category1"
            :category2.sync="dataSync.category2"
          />
        </td>
      </tr>
      <tr>
        <th>구매요청서 연결</th>
        <td colspan="3" style="height: 73px">
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="$emit('onClickPurchaseWfPostSearch')"
            >
              구매요청 정보 불러오기
            </button>
          </div>
          <ConnectEdit
            :dataList="purchaseWfDataList"
            :disabledData="{ aprvlStatus: '-' }"
            @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
            @onClickRemove="
              (deletedDataList) => $emit('onClickRemovePurchaseWfPost', deletedDataList)
            "
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";
import Input from "@/components/common/input/Input";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import ConnectEdit from "@/components/common/connect/ConnectEdit";
import ConnectView from "@/components/common/connect/ConnectView";

export default {
  name: "AnnounceInfo",
  components: {
    TableViewWithTitle,
    DateTimePicker,
    Input,
    CategoryGroupIncludeData,
    ConnectEdit,
    ConnectView,
  },
  props: {
    newNumber: String,
    dataSync: Object,
    // startDate: Date,
    // endDate: Date,
    // title: String,
    // category0: Number | null,
    // category1: Number | null,
    // category2: Number | null,
    purchaseWfDataList: Array,
    requestPostList: Array,
  },
  methods: {
    setCategoryForce(category0, category1, category2) {
      this.$refs.category.setForce(category0, category1, category2);
    },
  },
};
</script>
