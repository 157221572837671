export function getIsValidEmail(obj) {
  const { name, domain } = obj;

  if (!name || !name.trim()) return false;
  if (!domain || !domain.trim()) return false;

  return true;
}

export function getEmailTextFull(objEmail) {
  const ret = `${objEmail.name}@${objEmail.domain}`;
  return ret;
}

export function setEmail(strEmail, objEmail) {
  const arr = strEmail.split("@");
  if (arr.length !== 2) return false;

  objEmail.name = arr[0];
  objEmail.domain = arr[1];

  return true;
}
